import React from 'react';

import Layout from 'layout/index';

import SEO from 'components/seo/index';

import Registeryour from 'components/register-plantation/registeryour';



// markup
const Registeryourplantation = () => {
  return (
    <>
      <SEO title='Forests By Heartfulness | Register Your plantation' />
      <Layout>
        <section id='registeryourplantation'>
          <Registeryour />
        </section>
      </Layout>
    </>
  );
};

export default Registeryourplantation;