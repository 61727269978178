import React from 'react';

//import 'assets/styles/pages/plantationbread.scss';

import 'assets/styles/pages/plantationregister.scss';

import homeicon from 'assets/images/icons/icon-md-home.svg';

import Backleftarrow from 'assets/images/icons/back-arrow-left.svg';

import Breadrightarrow from 'assets/images/icons/bread-arrow-right.png';

import { Link, navigate } from 'gatsby';
import Loginvisitor from 'components/initiative/loginvisitor';
import { isMobile } from 'react-device-detect';

export default function Plantationbreadcumb(props) {
  const navigateToHome = () => {
    navigate("/planter-details");
  }
  return (
    <>

      {/* <Loginvisitor /> */}
      <div className='register-breadcumb'>
        <div className='container'>
          <div className='row'>
            <div className='col-md-9'>
              <div className='bread-title-bar'>
                <span className='hand-pointer'>

                  <img src={homeicon} alt='treeRightIconGreen' onClick={navigateToHome} />
                </span>
                <span className='bread-right-arrow-small'>

                  <img src={Breadrightarrow} alt='treeRightIconGreen' />
                </span>
                <h3 className='bread-title-text'> {props.name} </h3>
              </div>
            </div>
            <div className={isMobile ? `col-md-3 text-right margin-admin-10` : `col-md-3 text-right`}>
              <Link to={props.backTo} className='btn btn-viewmore'>
                <span className='backarrow-left'>

                  <img src={Backleftarrow} alt='Back arrow' />
                </span>
                Back
              </Link>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
