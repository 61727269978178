import React, { useEffect, useState } from 'react';

import 'assets/styles/pages/login-visitor.scss';

import logouticon from 'assets/images/initiative/Icon-feather-log-out.png';
import logouticonone from 'assets/images/initiative/Icon-feather-log-out-1.svg';
import { Link, navigate } from 'gatsby';
import { doLogout } from 'components/Auth';

import config from 'assets/config';

export default function Loginvisitor(props) {
  const [path, setPath] = useState("");
  const [userD, setUserD] = useState("");
  // const storageInfo = (typeof window !== 'undefined' && window.localStorage) ? JSON.parse(localStorage.getItem("hfnAuthUserDetails")) : null;
  // const profileName = (storageInfo && storageInfo.srcmProfile !== null) ? storageInfo.srcmProfile.displayName : ""

  const signOut = () => {
    doLogout()
    navigate("/")
  }
  useEffect(() => {
    const uDetails = (typeof window !== 'undefined' && window.localStorage) ? localStorage.getItem("hfnAuthUserDetails") : null
    if (uDetails) {
      setUserD(JSON.parse(uDetails))
      setPath(window.location.pathname)
    }
  }, [(typeof window !== 'undefined' && window.localStorage) ? localStorage : []]);
  return (
    <div className='visitor-login-wrapper pad40'>
      <div className='container'>
        {(path === config.prefixPath+"/planter-details" || path === config.prefixPath+"/planter-details/") ? <div className='row'>
          <div className='col-md-9'>
            <div className='welcome-text-1'> Welcome</div>
            <div className='visitor-name-planter ellispe'>{(userD && userD.displayName !== null) ? userD.displayName : "No Name"} </div>
            <div className='visitor-mail-planter ellispe'> {(userD && userD.srcmProfile.email !== null) ? userD.srcmProfile.email : ""} </div>
          </div>
          <div className='col-md-3'>
            {(path === config.prefixPath+"/planter-details" || path === config.prefixPath+"/planter-details/") ? <Link className="btn btn-register" to="/registeryour-plantation"> REGISTER NOW </Link> : <></>}
            <p className={`log-out-text-1 paading-10-logout ${(path === config.prefixPath+"/trackaSapling" || path === config.prefixPath+"/trackaSapling/") ? 'margin-top-login' : ''}`} onClick={signOut}>
              Log out
              <span>
                <img src={logouticonone} className='img-fluid logout-icon' alt='Logout Icon' />
              </span>
            </p>
          </div>
        </div> :
          <div className='row'>
            <div className='col-md-9'>
              <div className='welcome-text'> Welcome</div>
              <div className='visitor-name ellispe'>{(userD && userD.displayName !== null) ? userD.displayName : "No Name"} </div>
              <div className='visitor-mail ellispe'> {(userD && userD.srcmProfile.email !== null) ? userD.srcmProfile.email : ""} </div>
            </div>
            <div className='col-md-3'>
              {(path === config.prefixPath+"/planter-details" || path === config.prefixPath+"/planter-details/") ? <Link className="btn btn-register" to="/registeryour-plantation"> REGISTER NOW </Link> : <></>}
              <p className={`log-out-text paading-10-logout ${(path === config.prefixPath+"/trackaSapling" || path === config.prefixPath+"/trackaSapling/") ? 'margin-top-login' : ''}`} onClick={signOut}>
                Log out
                <span>
                  <img src={logouticon} className='img-fluid logout-icon' alt='Logout Icon' />
                </span>
              </p>
            </div>
          </div>}
      </div>
    </div>
  );
}
