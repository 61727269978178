import React from 'react';

import Form from 'react-bootstrap/Form';

import { useForm } from "react-hook-form";

import Plantationbreadcumb from 'components/register-plantation/plantationbread';

import 'assets/styles/pages/plantationregister.scss';

export default function Registeryour() {
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm();

  const onSubmit = async (data) => { console.log(data) }
  const resetVal = () => { reset }
  return (
    <>
      <div>
        <Plantationbreadcumb />
      </div>

      <div className='register-plantation'>
        <div className='container'>
          <div className='row'>
            <div className='col-md-12 .mg-top20'>
              <h3 className='title-item'> Register Your Plantation </h3>
            </div>
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className='row'>
                <div className='col-md-4'>
                  <div className='fc-wrapper'>
                    <label htmlFor='name' className='form-label'>
                      Name
                    </label>
                    <input type='text' className='form-control ' id='name' placeholder=''
                      {...register("Name", {
                        required: "Name is required",
                        pattern: {
                          value: /^[a-zA-Z ]{2,40}$/,
                          message: "Please enter a valid Name",
                        },
                      })} />

                    {errors.Name && (
                      <span className="form-error">
                        {errors.Name.message}
                      </span>
                    )}
                  </div>
                </div>

                <div className='col-md-4'>
                  <div className='fc-wrapper'>
                    <label htmlFor='project' className='form-label'>
                      Project name ( Select the Project name)
                    </label>
                    <input type='text' name='project' className='form-control ' id='project' placeholder='' />
                  </div>
                </div>

                <div className='col-md-4'>
                  <div className='fc-wrapper'>
                    <label htmlFor='state' className='form-label'>
                      State
                    </label>
                    <input type='text' name='state' className='form-control ' id='state' placeholder='' />
                  </div>
                </div>

                <div className='col-md-4'>
                  <div className='fc-wrapper'>
                    <label htmlFor='plantername' className='form-label'>
                      Planter Name
                    </label>
                    <input type='text' name='plantername' className='form-control ' id='plantername' placeholder='' />
                  </div>
                </div>

                <div className='col-md-4'>
                  <div className='fc-wrapper'>
                    <label htmlFor='village' className='form-label'>
                      Village,/city
                    </label>
                    <input type='text' name='village' className='form-control ' id='village' placeholder='' />
                  </div>
                </div>

                <div className='col-md-4'>
                  <div className='fc-wrapper'>
                    <label htmlFor='pincode' className='form-label'>
                      Pincode
                    </label>
                    <input type='text' name='pincode' className='form-control ' id='pincode' placeholder='' />
                  </div>
                </div>

                <div className='col-md-4'>
                  <div className='fc-wrapper'>
                    <label htmlFor='date' className='form-label'>
                      Date of plantation
                    </label>
                    <input type='date' name='date' className='form-control ' id='date' placeholder='' />
                  </div>
                </div>

              </div>
              <div className='text-right'>
                <button className='btn btn-viewmore save-width'> Register My Saplings </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
}
